<template>
  <validation-observer ref="simpleRules">
    <div>
      <div class="card">
        <div class="d-lg-flex justify-content-between align-items-center px-2 mt-2">
          <h3 class="font-weight-bolder">
            {{ $t('qcSystem') }}
          </h3>

          <div class="d-flex">
            <div class="income-order-search order-search w-100 ">
              <div class="anan-input search-input">
                <div class="anan-input__inner anan-input__inner--normal pl-75 pt-25 pb-25 pr-25">
                  <!-- <input
                    v-model="search"
                    type="text"
                    placeholder="ค้นหา"
                    class="anan-input__input"
                  >
                  <div class="anan-input__suffix">
                    <i class="anan-input__clear-btn anan-icon" />
                    <i class="anan-input__suffix-icon anan-icon">
                      <i class="fal fa-search" />
                    </i>
                  </div> -->
                  <input
                    v-model="search_val"
                    type="text"
                    :placeholder="$t('search')"
                    class="anan-input__input"
                    @keyup.enter="getData()"
                  >
                  <div class="anan-input__suffix">
                    <button
                      type="button"
                      class="anan-button anan-button--normal anan-button--primary"
                      @click="getData"
                    >
                      <i class="fal fa-search" />
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- <b-form-select
              v-model="selected"
              :options="options"
              class="w-50 ml-1"
              size="sm"
            /> -->
          </div>
        </div>
        <div class="px-2  mt-1 mt-lg-0">
          <button
            type="button"
            class="anan-button--xl-large anan-button anan-button--primary anan-button--normal"
            @click="AddData"
          >
            <span>+ {{ $t('addNewItem') }} </span>
          </button>
        </div>
        <div class="pl-2 pr-2 pt-1">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            show-empty
          >
            <template #empty>
              <div class="text-center">
                <img
                  src="@/assets/images/anan-img/empty/empty.png"
                  alt="empty"
                  width="180px"
                >
                <p>No Data</p>
              </div>
            </template>

            <template #cell(number)="data">
              <b-link :to="{name: 'admin-user-type-edit', params: {id: data.item.id}}">
                GUSR-{{ items.length - (perPage * (currentPage - 1) + (data.index + 1)) + 1 }}
              </b-link>
            </template>
            <template #cell(qc_postnumber)="data">
              <router-link
                v-for="(item,index) in data.item.qc_postnumber"
                :key="item._id"
                :to="{name : 'admin-tracking-edit' , params: { id: item.postnumber_id }}"
              >
                {{ item.postnumber_name }} {{ (data.item.qc_postnumber.length - 1) > index ? ',':'' }}
              </router-link>
            </template>
            <template #cell(actions)="data">
              <feather-icon
                icon="Edit3Icon"
                class="mr-1 text-primary cursor-pointer"
                @click="EditType(data.item)"
              />
            </template>
          </b-table>

          <div class="demo-spacing-0 d-flex justify-content-end mb-2">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
              @input="getData()"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </div>

    </div>
  </validation-observer>
</template>

<script>
import {
  BTable, BLink,
  // BFormSelect,
  BPagination,
} from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BTable,
    BLink,
    // BFormSelect,
    ValidationObserver,
    BPagination,
  },
  data() {
    return {
      isActive: 'all',
      required,
      selected: null,
      amount: 0,
      detail: '',
      type: null,
      customerCode: '',
      prefix: '',
      prefixNum: '',

      options: [
        { value: null, text: 'ทั้งหมด' },
        { value: 1, text: 'รถ' },
        { value: 2, text: 'เรือ' },
      ],
      pageOptions: [5, 10, 15, 20],
      currentPage: 1,
      perPage: 5,
      totalRows: 1,
      items: [],
      search_val: '',
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'qc_name', label: this.$t('number'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'remark', label: this.$t('remarks'), thStyle: { width: '10%' }, thClass: 'text-center',
        },
        {
          key: 'qc_postnumber', label: this.$t('parcelCode'), sortable: false, thStyle: { width: '60%' }, thClass: 'text-left',
        },
        {
          key: 'quantity_notqc', label: this.$t('notYetQC'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'quantity_qc', label: this.$t('qcDone'), sortable: false, thStyle: { width: '10%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'actions', label: this.$t('manage'), sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-center',
        },
      ]
    },
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        const params = {
          page: this.currentPage,
          perPage: this.perPage,
          search_val: this.search_val,
        }
        const { data: res } = await this.$http.get('/qcpostnumber/showall', { params })
        this.items = res.data
        this.totalRows = res.total
      } catch (e) {
        console.log(e)
      }
    },
    EditType(data) {
      // eslint-disable-next-line no-underscore-dangle
      this.$router.push({ name: 'admin-warehouse-qc-edit', params: { id: data._id } })
    },
    AddData() {
      this.$swal({
        title: 'ต้องการเพิ่มรายการ',
        text: 'เพิ่มรายการ QC',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.post('qcpostnumber/store').then(response => {
            // eslint-disable-next-line no-underscore-dangle
            this.$router.push({ name: 'admin-warehouse-qc-edit', params: { id: response.data.data._id } })
          })
        }
      })
    },
    DeleteData(data) {
      this.$bvModal.msgBoxConfirm('คุณต้องการลบข้อมูลใช่หรือไม่?', {
        title: 'ยืนยันการลบข้อมูล',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
        .then(value => {
          if (value) {
            const params = {
              id: data.id,
            }
            this.$http.post('groupbyuser/list/delete', params).then(() => {
              this.getData(this.isActive)
              this.$bvToast.toast('ลบข้อมูลสำเร็จ', {
                title: 'สำเร็จ',
                variant: 'success',
                solid: true,
                autoHideDelay: 1500,
              })
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
  },
}
</script>

                  <style lang="scss" scoped></style>
